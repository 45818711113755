<template>
  <v-container fluid>
    <TableViewComponent
      title="Table activités"
      @addItemEvent="onAddElement()"
      addItemLabel="ajouter une activité"
      itemLabel="activité"
      itemsLabel="activités"
      :items="activities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cette activité?"
      :loading="loading"
      :vcols="{ xl: '6', lg: '10', md: '10', sm: '12', xs: '12' }"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"

    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >

    <!-- Fenetre modale pour l'interdiction de modification du nombre de montant -->
    <StandardDialogInfo
    title="Suppression impossible"
    label="Vous ne pouvez pas supprimer cette activité car elle est associé à au moins 1 template de capacités."
    :labelHtml="false"
    textButton="OK"
    :visible.sync="showStandardDialog">
    </StandardDialogInfo>

  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import StandardDialogInfo from "@/components/ui/StandardDialogInfo.vue"
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { ActivityService } from "@/service/sfr/activity_service.js";
import { MetierService } from "@/service/sfr/metier_service.js";
import { CapacitiesService } from "@/service/etablishment/capacities_service.js";


import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";


export default {
  name: "TableActivities",
  components: { TableViewComponent, StandardDialogInfo, },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les données. chaque élément doit avoir un id, ainsi que les fonctions edit, view, delete */
      activities: [],

      /**le service d'accès aux activités */
      activitiesService: null,
      /**le service d'accès aux métiers */
      metiersService: null,
      /** le service pour les capacités */
      capacitiesService: null,

      showStandardDialog: false,
    };
  },
  methods: {
    /** On part vers la page d'ajout*/
    onAddElement() {
      this.$router.push(routes.activities.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.activities = [];

        let activities = await this.activitiesService.getActivities();

        let metiers = await this.metiersService.getAll();

        activities.forEach((a) => {
          let activity = JSON.parse(JSON.stringify(a));
          activity.metierId = a.metier;
          activity.metier = metiers.find((m) => m.id == a.metier).name;          

          activity.view = () => {
            this.$router.push(routes.activities.view.root + "/" + activity.id);
          };

          //action de modification
          activity.edit = () => {
            this.$router.push(routes.activities.edit.root + "/" + activity.id);
          };

          activity.delete = async () => {
            try {

              // Vérification si présence de template de capacité
              let capacities = await this.capacitiesService.getCapacityTemplatesByActivity(activity.id);

              // Si présence de capacités, on affiche la popup
              if (capacities.length > 0) {
                this.showStandardDialog = true;
              } else {
                // Pas de capacités, on tente de supprimer l'activité
                await this.activitiesService.deleteActivity(activity.id);
                this.load();
              }
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression de l'activité : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.activities.push(activity);
        });

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Nom abrégé",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Nom digital",
          align: "start",
          sortable: true,
          value: "digitalName",
        },
        {
          text: "Métier",
          align: "start",
          sortable: true,
          value: "metier",
        },
        {
          text: "Déclinaison Avec",
          align: "start",
          sortable: true,
          value: "label",
          default: false,
        },
        {
          text: "Nom complet",
          align: "start",
          sortable: true,
          value: "fullName",
          default: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },

  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditServiceSFR];
    },
     /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditServiceSFR, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [ RolesApplicationEnum.ReadActivities] ;
    },
  },
  mounted() {
    //on instancie les services
    this.activitiesService = new ActivityService(this.$api);
    this.metiersService = new MetierService(this.$api.getMetierApi());
    this.capacitiesService = new CapacitiesService(this.$api.getCapacitiesApi())

    this.load();
  },
};
</script>

<style>
</style>